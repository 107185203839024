body, #root, html {
  overflow: hidden;
  font-family: Roboto;

  touch-action: manipulation;
}

.hidden-overflow {
  overflow: hidden;
}

.tippy-content div {
  display: flex;
  align-items: center;
  width: 12.9375rem;
  height: 1.4375rem;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.tippy-content div p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 10px;
  
  color: #000000;
}